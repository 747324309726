import React, { useEffect, useRef, useCallback } from 'react';

const NOTE_FREQUENCIES = {
  'C3': 130.81, 'D3': 146.83, 'E3': 164.81, 'F3': 174.61,
  'G3': 196.00, 'A3': 220.00, 'B3': 246.94
};

const NOTES = ['C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3'];
const BEAT_DURATION = 0.125; // 16th note duration
const BEATS_PER_MEASURE = 16;
const MEASURE_DURATION = BEAT_DURATION * BEATS_PER_MEASURE;
const MELODY_DURATION = MEASURE_DURATION * 4; // 4 measures
const NOTE_DURATION = BEAT_DURATION; // Each note lasts one beat

function BlockchainMusic({ isPlaying, currentBlock, onNoteChange, onAudioData }) {
  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const isAudioInitializedRef = useRef(false);
  const beatOscillatorsRef = useRef([]);
  const beatGainNodesRef = useRef([]);
  const melodyIntervalRef = useRef(null);
  const beatIntervalRef = useRef(null);
  const analyserRef = useRef(null);

  const cleanupAudio = () => {
    console.log('Cleaning up audio');
    if (audioContextRef.current) {
      // Stop and disconnect all oscillators
      beatOscillatorsRef.current.forEach(osc => {
        if (osc.started) {
          osc.stop();
        }
        osc.disconnect();
      });
      beatOscillatorsRef.current = [];

      // Disconnect all gain nodes
      beatGainNodesRef.current.forEach(gain => gain.disconnect());
      beatGainNodesRef.current = [];

      // Clear the melody and beat intervals
      if (melodyIntervalRef.current) {
        clearInterval(melodyIntervalRef.current);
        melodyIntervalRef.current = null;
      }
      if (beatIntervalRef.current) {
        clearInterval(beatIntervalRef.current);
        beatIntervalRef.current = null;
      }

      // Disconnect the main gain node
      if (gainNodeRef.current) {
        gainNodeRef.current.disconnect();
      }

      // Close the audio context only if it's not already closed
      if (audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close().then(() => {
          console.log('Audio context closed');
        }).catch(error => {
          console.error('Error closing AudioContext:', error);
        });
      }

      audioContextRef.current = null;
      isAudioInitializedRef.current = false;
    }
  };

  const initializeAudio = useCallback(() => {
    if (!isAudioInitializedRef.current) {
      console.log('Initializing audio context');
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      gainNodeRef.current = audioContextRef.current.createGain();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048; // Increase this for more detail
      analyserRef.current.smoothingTimeConstant = 0.3; // Adjust this for smoother transitions
      gainNodeRef.current.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);
      isAudioInitializedRef.current = true;
    }
  }, []);

  const sendAudioData = () => {
    if (analyserRef.current && onAudioData && isPlaying) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);
      onAudioData(dataArray);
    }
    requestAnimationFrame(sendAudioData);
  };

  useEffect(() => {
    if (isPlaying) {
      sendAudioData();
    }
  }, [isPlaying]);

  const setupBeatOscillators = () => {
    // Clear existing oscillators and gain nodes
    beatOscillatorsRef.current.forEach(osc => {
      if (osc.started) {
        osc.stop();
      }
    });
    beatOscillatorsRef.current = [];
    beatGainNodesRef.current = [];

    // Initialize new beat oscillators and gain nodes
    for (let i = 0; i < 4; i++) {
      const osc = audioContextRef.current.createOscillator();
      const gain = audioContextRef.current.createGain();
      osc.connect(gain);
      gain.connect(audioContextRef.current.destination);
      
      osc.started = false; // Add a flag to track if the oscillator has been started
      beatOscillatorsRef.current.push(osc);
      beatGainNodesRef.current.push(gain);

      // We don't start the oscillator here anymore
    }
  };

  useEffect(() => {
    console.log('isPlaying:', isPlaying, 'currentBlock:', currentBlock);
    if (isPlaying && currentBlock) {
      console.log('Starting music');
      cleanupAudio(); // Clean up any existing audio before starting
      initializeAudio();
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume().then(() => {
          console.log('AudioContext resumed');
          setupBeatOscillators();
          playMusic(currentBlock);
        });
      } else {
        setupBeatOscillators();
        playMusic(currentBlock);
      }
    } else {
      console.log('Stopping music');
      cleanupAudio();
    }

    return () => {
      cleanupAudio();
    };
  }, [isPlaying, currentBlock, initializeAudio]);

  const playMusic = (block) => {
    try {
      if (block.header_hash) {
        console.log('Playing melody with header_hash:', block.header_hash);
        const melody = generateMelody(block.header_hash);
        playMelodyAndBeat(melody);
      } else {
        console.warn('header_hash is not available in the currentBlock');
        const defaultMelody = generateDefaultMelody();
        playMelodyAndBeat(defaultMelody);
      }
    } catch (error) {
      console.error('Error playing music:', error);
    }
  };

  const playMelodyAndBeat = (melody) => {
    // Safety check - if audio context is not available, don't proceed
    if (!audioContextRef.current) {
      console.warn('Audio context not available, reinitializing...');
      initializeAudio();
      if (!audioContextRef.current) {
        console.error('Failed to initialize audio context');
        return;
      }
    }

    const now = audioContextRef.current.currentTime;
    const startTime = now + 0.1; // Small delay to ensure all notes are scheduled properly
    let nextScheduleTime = startTime;  // Track when next loop should start

    // Clear any existing intervals
    if (melodyIntervalRef.current) {
      clearInterval(melodyIntervalRef.current);
    }
    if (beatIntervalRef.current) {
      clearInterval(beatIntervalRef.current);
    }

    const playOnce = (time) => {
      // Safety check - if audio context was cleaned up, stop the loop
      if (!audioContextRef.current) {
        console.log('Audio context no longer available, stopping playback');
        return;
      }

      const beatPattern = [
        [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0], // Kick
        [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1], // Snare
        [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // Hi-hat
        [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 1]  // Percussion
      ];

      // Safety check for oscillators and gain nodes
      if (!beatOscillatorsRef.current.length || !beatGainNodesRef.current.length) {
        console.warn('Beat oscillators or gain nodes not available');
        return;
      }

      const [kickOsc, snareOsc, hihatOsc, percOsc] = beatOscillatorsRef.current;
      const [kickGain, snareGain, hihatGain, percGain] = beatGainNodesRef.current;

      // Schedule the next loop slightly before this one ends
      const scheduleAhead = 0.1; // Schedule 100ms ahead
      if (audioContextRef.current.currentTime + scheduleAhead >= nextScheduleTime) {
        for (let measure = 0; measure < 4; measure++) {
          for (let beat = 0; beat < BEATS_PER_MEASURE; beat++) {
            const noteTime = nextScheduleTime + measure * MEASURE_DURATION + beat * BEAT_DURATION;
            const { note, char, index } = melody[(measure * BEATS_PER_MEASURE + beat) % melody.length];
            
            playPianoNote(note, noteTime, BEAT_DURATION);
            
            if (beatPattern[0][beat]) playKick(kickOsc, kickGain, noteTime);
            if (beatPattern[1][beat]) playSnare(snareOsc, snareGain, noteTime);
            if (beatPattern[2][beat]) playHiHat(hihatOsc, hihatGain, noteTime);
            if (beatPattern[3][beat]) playPerc(percOsc, percGain, noteTime);
            
            // Safety check before scheduling note change
            if (audioContextRef.current) {
              setTimeout(() => onNoteChange({ note, char, index }), 
                (noteTime - audioContextRef.current.currentTime) * 1000);
            }
          }
        }
        nextScheduleTime += MELODY_DURATION; // Update next schedule time
      }

      // Only continue the loop if audio context is still available
      if (audioContextRef.current) {
        requestAnimationFrame(() => playOnce(nextScheduleTime));
      }
    };

    // Start the loop
    playOnce(startTime);
  };

  const playKick = (osc, gainNode, time) => {
    if (!osc.started) {
      osc.start(time);
      osc.started = true;
    }
    osc.frequency.setValueAtTime(120, time);
    osc.frequency.exponentialRampToValueAtTime(30, time + 0.1);
    gainNode.gain.setValueAtTime(0, time);
    gainNode.gain.linearRampToValueAtTime(0.8, time + 0.01);
    gainNode.gain.exponentialRampToValueAtTime(0.01, time + 0.3);
  };

  const playSnare = (osc, gainNode, time) => {
    if (!osc.started) {
      osc.start(time);
      osc.started = true;
    }
    osc.frequency.setValueAtTime(250, time);
    osc.frequency.exponentialRampToValueAtTime(350, time + 0.05);
    gainNode.gain.setValueAtTime(0, time);
    gainNode.gain.linearRampToValueAtTime(0.7, time + 0.01);
    gainNode.gain.exponentialRampToValueAtTime(0.01, time + 0.2);

    // Add noise to the snare
    const noiseBuffer = audioContextRef.current.createBuffer(1, audioContextRef.current.sampleRate * 0.1, audioContextRef.current.sampleRate);
    const noiseData = noiseBuffer.getChannelData(0);
    for (let i = 0; i < noiseData.length; i++) {
      noiseData[i] = Math.random() * 2 - 1;
    }
    const noiseSource = audioContextRef.current.createBufferSource();
    noiseSource.buffer = noiseBuffer;
    const noiseGain = audioContextRef.current.createGain();
    noiseSource.connect(noiseGain);
    noiseGain.connect(audioContextRef.current.destination);
    noiseGain.gain.setValueAtTime(0, time);
    noiseGain.gain.linearRampToValueAtTime(0.4, time + 0.01);
    noiseGain.gain.exponentialRampToValueAtTime(0.01, time + 0.1);
    noiseSource.start(time);
  };

  const playHiHat = (osc, gainNode, time) => {
    if (!osc.started) {
      osc.start(time);
      osc.started = true;
    }
    osc.frequency.setValueAtTime(10000, time);
    osc.type = 'sine'; // Changed from 'highpass' to 'sine'
    gainNode.gain.setValueAtTime(0, time);
    gainNode.gain.linearRampToValueAtTime(0.3, time + 0.01);
    gainNode.gain.exponentialRampToValueAtTime(0.01, time + 0.05);

    // Add some noise to the hi-hat
    const noiseBuffer = audioContextRef.current.createBuffer(1, audioContextRef.current.sampleRate * 0.1, audioContextRef.current.sampleRate);
    const noiseData = noiseBuffer.getChannelData(0);
    for (let i = 0; i < noiseData.length; i++) {
      noiseData[i] = Math.random() * 2 - 1;
    }
    const noiseSource = audioContextRef.current.createBufferSource();
    noiseSource.buffer = noiseBuffer;
    const noiseGain = audioContextRef.current.createGain();
    noiseSource.connect(noiseGain);
    noiseGain.connect(audioContextRef.current.destination);
    noiseGain.gain.setValueAtTime(0, time);
    noiseGain.gain.linearRampToValueAtTime(0.1, time + 0.01);
    noiseGain.gain.exponentialRampToValueAtTime(0.01, time + 0.05);
    noiseSource.start(time);
  };

  const playPerc = (osc, gainNode, time) => {
    if (!osc.started) {
      osc.start(time);
      osc.started = true;
    }
    osc.frequency.setValueAtTime(600, time);
    osc.frequency.exponentialRampToValueAtTime(300, time + 0.1);
    gainNode.gain.setValueAtTime(0, time);
    gainNode.gain.linearRampToValueAtTime(0.5, time + 0.01);
    gainNode.gain.exponentialRampToValueAtTime(0.01, time + 0.1);
  };

  const hexToNote = (hexChar) => {
    const value = parseInt(hexChar, 16);
    return NOTES[value % 7];
  };

  const generateMelody = (puzzleHash) => {
    const melody = [];
    let i = 0;
    while (melody.length < 64) {
      const char = puzzleHash[i % puzzleHash.length];
      const note = hexToNote(char);
      if (note) {
        melody.push({ note, char, index: i });
      }
      i++;
    }
    return melody;
  };

  const playPianoNote = (note, time, duration) => {
    const frequency = NOTE_FREQUENCIES[note];
    
    // Check if the frequency is a finite number
    if (!isFinite(frequency)) {
      console.warn(`Invalid frequency for note: ${note}`);
      return; // Skip this note
    }

    const oscillator = audioContextRef.current.createOscillator();
    const envelope = audioContextRef.current.createGain();

    oscillator.type = 'triangle';
    oscillator.frequency.setValueAtTime(frequency, time);

    envelope.gain.setValueAtTime(0, time);
    envelope.gain.linearRampToValueAtTime(1, time + 0.01);
    envelope.gain.linearRampToValueAtTime(0.7, time + 0.1);
    envelope.gain.exponentialRampToValueAtTime(0.00001, time + duration);

    oscillator.connect(envelope);
    envelope.connect(gainNodeRef.current);

    oscillator.start(time);
    oscillator.stop(time + duration);
  };

  const generateDefaultMelody = () => {
    const defaultNotes = ['C3', 'E3', 'G3', 'C3', 'E3', 'G3', 'B3', 'C3'];
    const melody = [];
    
    while (melody.length < 64) {
      const note = defaultNotes[melody.length % defaultNotes.length];
      melody.push({ note, char: '-', index: melody.length });
    }

    return melody;
  };

  const stopSound = () => {
    console.log('Stopping sound');
    cleanupAudio();
  };

  return null; // This component doesn't render anything
}

export default BlockchainMusic;
