import React, { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { PLAY_DIRECTION } from '../App';
import './CassetteTape.css';

const REEL_RPM = 23.9;
const REEL_RPS = REEL_RPM / 60;

function CassetteTape({ isPlaying, playDirection, fastSpeed, isFlipped }) {
  const frontCanvasRef = useRef(null);
  const backCanvasRef = useRef(null);
  const frontAppRef = useRef(null);
  const backAppRef = useRef(null);
  const reelsRef = useRef({ front: { left: null, right: null }, back: { left: null, right: null } });
  const spritesCreatedRef = useRef({ front: false, back: false });

  useEffect(() => {
    const setupApp = (canvasRef, appRef, isFront) => {
      const app = new PIXI.Application({
        width: 600,
        height: 400,
        backgroundColor: 0xfb81ed,
        resolution: window.devicePixelRatio || 1,
      });
      canvasRef.current.appendChild(app.view);
      appRef.current = app;

      PIXI.Assets.load(['/textures/cassette_sidea.png', '/textures/cassette_sideb.png', '/textures/cassette_internal.png', '/textures/reel.png']).then((textures) => {
        if (appRef.current && !spritesCreatedRef.current[isFront ? 'front' : 'back']) {
          createSprites(appRef.current, textures, isFront);
          spritesCreatedRef.current[isFront ? 'front' : 'back'] = true;
        }
      });
    };

    setupApp(frontCanvasRef, frontAppRef, true);
    setupApp(backCanvasRef, backAppRef, false);

    return () => {
      if (frontAppRef.current) frontAppRef.current.destroy(true, true);
      if (backAppRef.current) backAppRef.current.destroy(true, true);
    };
  }, []);

  const createSprites = (app, textures, isFront) => {
    const cassette = new PIXI.Sprite(isFront ? textures['/textures/cassette_sidea.png'] : textures['/textures/cassette_sideb.png']);
    const cassetteInternal = new PIXI.Sprite(textures['/textures/cassette_internal.png']);
    const leftReel = new PIXI.Sprite(textures['/textures/reel.png']);
    const rightReel = new PIXI.Sprite(textures['/textures/reel.png']);

    // Create cassette side a sprite
    cassette.anchor.set(0.5);
    cassette.position.set(app.screen.width / 2, app.screen.height / 2);
    cassette.scale.set(1);

    // Create cassette internal sprite
    cassetteInternal.anchor.set(0.5);
    cassetteInternal.position.set(app.screen.width / 2, app.screen.height / 2);
    cassetteInternal.scale.set(1);

    // Create reel sprite
    leftReel.anchor.set(0.495);
    leftReel.position.set(app.screen.width / 2 - 123, app.screen.height / 2 - 15);
    leftReel.scale.set(1);

    // Create reel sprite
    rightReel.anchor.set(0.495);
    rightReel.position.set(app.screen.width / 2 + 116, app.screen.height / 2 - 17);
    rightReel.scale.set(1);

    app.stage.addChild(cassetteInternal);
    app.stage.addChild(leftReel);
    app.stage.addChild(rightReel);
    app.stage.addChild(cassette);

    reelsRef.current[isFront ? 'front' : 'back'] = { left: leftReel, right: rightReel };

    const dropShadowFilter = new DropShadowFilter({
      alpha: 0.5,
      blur: 4,
      distance: 5,
      angle: Math.PI / 4,
    });
    cassette.filters = [dropShadowFilter];
  };

  useEffect(() => {
    const animate = (delta) => {
      const currentReels = isFlipped ? reelsRef.current.back : reelsRef.current.front;
      if (isPlaying && currentReels.left && currentReels.right) {
        let direction;
        switch (playDirection) {
          case PLAY_DIRECTION.PLAY:
          case PLAY_DIRECTION.FAST_FORWARD:
            direction = -1;
            break;
          case PLAY_DIRECTION.REVERSE:
            direction = 1;
            break;
          default:
            direction = 0;
        }

        const speed = playDirection === PLAY_DIRECTION.PLAY ? 1 : fastSpeed;
        const rotationAmount = (REEL_RPS * 2 * Math.PI * delta) / 60;

        currentReels.left.rotation += direction * speed * rotationAmount;
        currentReels.right.rotation += direction * speed * rotationAmount;
      }
    };

    if (frontAppRef.current) {
      frontAppRef.current.ticker.add(animate);
    }
    if (backAppRef.current) {
      backAppRef.current.ticker.add(animate);
    }

    return () => {
      if (frontAppRef.current && frontAppRef.current.ticker) {
        frontAppRef.current.ticker.remove(animate);
      }
      if (backAppRef.current && backAppRef.current.ticker) {
        backAppRef.current.ticker.remove(animate);
      }
    };
  }, [isPlaying, playDirection, fastSpeed, isFlipped]);

  return (
    <div className="cassette-container">
      <div className={`cassette-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="cassette-side cassette-front" ref={frontCanvasRef}></div>
        <div className="cassette-side cassette-back" ref={backCanvasRef}></div>
      </div>
    </div>
  );
}

export default CassetteTape;