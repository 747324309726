import React, { useState, useEffect, useRef, useCallback } from 'react';
import CassetteTape from './components/CassetteTape';
import Controls from './components/Controls';
import { CRTDisplay } from './components/CRTDisplay';
import BlockchainMusic from './components/BlockchainMusic';
import Layout from './components/Layout';
import './App.css';

const API_URL = process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_API_URL || 'http://localhost:3001';
const BYTES_PER_SECOND = 27;
const BYTES_PER_BLOCK = 1700;
const SECONDS_PER_BLOCK = Math.ceil(BYTES_PER_BLOCK / BYTES_PER_SECOND);

export const PLAY_DIRECTION = {
  REVERSE: -1,
  STOP: 0,
  PLAY: 1,
  FAST_FORWARD: 2
};

const FAST_SPEEDS = {
  INITIAL: 4,
  MEDIUM: 10,
  MAX: 20
};

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [playDirection, setPlayDirection] = useState(PLAY_DIRECTION.STOP);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [nextBlock, setNextBlock] = useState(null);
  const [blockHeight, setBlockHeight] = useState(0);
  const [displayHeight, setDisplayHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [fastSpeed, setFastSpeed] = useState(FAST_SPEEDS.INITIAL);
  const [currentSpeed, setCurrentSpeed] = useState(1);
  const intervalRef = useRef(null);
  const speedChangeTimerRef = useRef(null);
  const [startValue, setStartValue] = useState(0);
  const counterRef = useRef(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [currentChar, setCurrentChar] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentHeaderHash, setCurrentHeaderHash] = useState(null);
  const [isFastMoving, setIsFastMoving] = useState(false);
  const [audioData, setAudioData] = useState(new Uint8Array(128));
  const [isTestnet, setIsTestnet] = useState(false);

  const fetchBlockchainState = useCallback(async () => {
    try {
      console.log('Fetching blockchain state...');
      const response = await fetch(`${API_URL}/api/blockchain_state?testnet=${isTestnet}`);
      const data = await response.json();
      console.log('Blockchain state:', data);
      setMaxHeight(data.peak.height);
      const initialHeight = Math.floor(Math.random() * data.peak.height) + 1;
      setBlockHeight(initialHeight);
      setDisplayHeight(initialHeight);
      setStartValue(initialHeight);
      fetchBlockData(initialHeight);
    } catch (error) {
      console.error('Failed to fetch blockchain state:', error);
    }
  }, [isTestnet]);

  useEffect(() => {
    fetchBlockchainState();
  }, [fetchBlockchainState]);

  const fetchBlockData = async (height, isNext = false) => {
    try {
      // Log the attempt to fetch block data
      console.log(`Fetching block data for height ${height}...`);
      
      // Make an API call to fetch the block data
      const response = await fetch(`${API_URL}/api/block/${height}?testnet=${isTestnet}`);
      const data = await response.json();
      
      // Log the received block data
      console.log('Block data:', data);
      
      if (isNext) {
        // If this is fetching the next block, update the nextBlock state
        setNextBlock(data);
      } else {
        // If this is fetching the current block, update the currentBlock state
        setCurrentBlock(data);
      }
    } catch (error) {
      // Log any errors that occur during the fetch
      console.error('Failed to fetch block data:', error);
    }
  };

  const clearIntervals = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (speedChangeTimerRef.current) clearTimeout(speedChangeTimerRef.current);
  };

  const updateBlockHeight = (newHeight) => {
    setBlockHeight(newHeight);
    setDisplayHeight(newHeight);
    if (nextBlock && nextBlock.height === newHeight) {
      setCurrentBlock(nextBlock);
      setNextBlock(null);
      fetchBlockData(newHeight + 1, true);
    } else {
      fetchBlockData(newHeight);
    }
  };

  const handleFastMove = (direction) => {
    clearIntervals();
    setIsPlaying(false);
    setIsMoving(true);
    setIsFastMoving(true);
    setPlayDirection(direction);
    setFastSpeed(FAST_SPEEDS.INITIAL);
    setCurrentSpeed(FAST_SPEEDS.INITIAL);

    const updateSpeed = (newSpeed) => {
      setFastSpeed(newSpeed);
      setCurrentSpeed(newSpeed);
      clearIntervals();
      intervalRef.current = setInterval(() => {
        setDisplayHeight(prevHeight => {
          const newHeight = direction === PLAY_DIRECTION.FAST_FORWARD
            ? Math.min(prevHeight + 1, maxHeight)
            : Math.max(1, prevHeight - 1);
          updateBlockHeight(newHeight);
          return newHeight;
        });
        counterRef.current?.startAnimation();
      }, SECONDS_PER_BLOCK * 1000 / newSpeed);
    };

    updateSpeed(FAST_SPEEDS.INITIAL);

    speedChangeTimerRef.current = setTimeout(() => {
      updateSpeed(FAST_SPEEDS.MEDIUM);
      speedChangeTimerRef.current = setTimeout(() => {
        updateSpeed(FAST_SPEEDS.MAX);
      }, 3000);
    }, 2000);
  };

  const handleFastForward = () => {
    handleFastMove(PLAY_DIRECTION.FAST_FORWARD);
    counterRef.current?.startAnimation();
  };

  const handleReverse = () => {
    handleFastMove(PLAY_DIRECTION.REVERSE);
    counterRef.current?.startAnimation();
  };

  const handlePlay = () => {
    clearIntervals();
    setIsPlaying(true);
    setIsMoving(true);
    setIsFastMoving(false);
    setPlayDirection(PLAY_DIRECTION.PLAY);
    setCurrentSpeed(1);
    setFastSpeed(FAST_SPEEDS.INITIAL);
    intervalRef.current = setInterval(() => {
      setDisplayHeight(prevHeight => {
        const newHeight = Math.min(prevHeight + 1, maxHeight);
        updateBlockHeight(newHeight);
        return newHeight;
      });
      counterRef.current?.startAnimation();
    }, SECONDS_PER_BLOCK * 1000);
    counterRef.current?.startAnimation();
  };

  const handleStop = () => {
    clearIntervals();
    setIsPlaying(false);
    setIsMoving(false);
    setIsFastMoving(false);
    setPlayDirection(PLAY_DIRECTION.STOP);
    setFastSpeed(FAST_SPEEDS.INITIAL);
    setCurrentSpeed(1);
    // Reset note information
    setCurrentNote(null);
    setCurrentChar(null);
    setCurrentIndex(null);
  };

  const handleFlip = () => {
    setIsFlipped(prev => !prev);
    setIsTestnet(prev => !prev);
    // Reset the current state and fetch new data for the other network
    setCurrentBlock(null);
    setNextBlock(null);
  };

  const handleNoteChange = ({ note, char, index }) => {
    if (isPlaying && !isFastMoving) {
      setCurrentNote(note);
      setCurrentChar(char);
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    if (currentBlock && currentBlock.header_hash) {
      setCurrentHeaderHash(currentBlock.header_hash);
    } else {
      setCurrentHeaderHash(null);
    }
  }, [currentBlock]);

  const handleAudioData = useCallback((data) => {
    setAudioData(data);
  }, []);

  useEffect(() => {
    if (isPlaying && !isFastMoving) {
      const animationFrame = requestAnimationFrame(() => handleAudioData(new Uint8Array(128)));
      return () => cancelAnimationFrame(animationFrame);
    }
  }, [isPlaying, isFastMoving, handleAudioData]);

  return (
    <Layout>
      <div className="App">
        <header className="App-header">
          <h1>Hash Tunes<span className="App-header-emoji">🌴</span></h1>
        </header>
        <main>
          <div className="cassette-container">
            <CassetteTape 
              isPlaying={isPlaying || isMoving} 
              playDirection={playDirection} 
              fastSpeed={fastSpeed} 
              isFlipped={isFlipped}
            />
          </div>
          <Controls
            onPlay={handlePlay}
            onReverse={handleReverse}
            onFastForward={handleFastForward}
            onStop={handleStop}
            onFlip={handleFlip}
            isPlaying={isPlaying}
            isMoving={isMoving}
            playDirection={playDirection}
            isTestnet={isTestnet}
          />
          <div className="crt-container">
            <CRTDisplay 
              block={currentBlock} 
              currentNote={isPlaying && !isFastMoving ? currentNote : null}
              currentChar={isPlaying && !isFastMoving ? currentChar : null}
              currentIndex={isPlaying && !isFastMoving ? currentIndex : null}
              headerHash={currentHeaderHash}
              audioData={audioData}
              isTestnet={isTestnet}
              isPlaying={isPlaying}
              isFastMoving={isFastMoving}
              playDirection={playDirection}
              currentSpeed={currentSpeed}
            />
          </div>
          <BlockchainMusic 
            isPlaying={isPlaying && !isFastMoving}
            currentBlock={currentBlock} 
            onNoteChange={handleNoteChange}
            onAudioData={handleAudioData}
          />
        </main>
      </div>
    </Layout>
  );
}

export default App;
