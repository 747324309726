import React from 'react';
import { PLAY_DIRECTION } from '../App';  // Import PLAY_DIRECTION from App
import './Controls.css';

function Controls({ onPlay, onReverse, onFastForward, onStop, onFlip, isPlaying, isMoving, playDirection, isTestnet }) {
  return (
    <div className="controls-container">
      <div className="controls">
        <button 
          onClick={onPlay} 
          disabled={playDirection === PLAY_DIRECTION.PLAY} 
          className="control-button play"
          aria-label="Play"
        >
          <div className="button-content">
            <span className="button-icon">▶</span>
            <span className="button-text">PLAY</span>
          </div>
        </button>
        <button 
          onClick={onReverse} 
          disabled={playDirection === PLAY_DIRECTION.REVERSE} 
          className="control-button rew"
          aria-label="Rewind"
        >
          <div className="button-content">
            <span className="button-icon">◀◀</span>
            <span className="button-text">REW</span>
          </div>
        </button>
        <button 
          onClick={onFastForward} 
          disabled={playDirection === PLAY_DIRECTION.FAST_FORWARD} 
          className="control-button ff"
          aria-label="Fast Forward"
        >
          <div className="button-content">
            <span className="button-icon">▶▶</span>
            <span className="button-text">FF</span>
          </div>
        </button>
        <button 
          onClick={onStop} 
          disabled={!isMoving} 
          className="control-button stop"
          aria-label="Stop"
        >
          <div className="button-content">
            <span className="button-icon">■</span>
            <span className="button-text">STOP</span>
          </div>
        </button>
        <button 
          onClick={onFlip} 
          disabled={isMoving || isPlaying}
          className="control-button flip"
          aria-label="Flip"
        >
          <div className="button-content">
            <span className="button-icon">↻</span>
            <span className="button-text">{isTestnet ? 'TESTNET' : 'MAINNET'}</span>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Controls;
