import React from 'react';
import './Layout.css';

export default function Layout({ children }) {
  return (
    <div className="App">
      {children}
      <footer className="powered-by-footer">
        <p>
        🌱 <br />Powered by <br /> <a 
            href="https://coinset.org"
            target="_blank"
            rel="noopener noreferrer"
          >coinset.org
          </a>
        </p>
      </footer>
    </div>
  );
} 